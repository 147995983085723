import React, { useEffect } from 'react'
import { Container, MainSection } from './styledComponents'
import { Layout } from 'antd'

import { RoutePaths } from 'core/Router/routePaths'
import { Route, Routes } from 'react-router-dom'

import AddNewPartner from 'core/Partners/AddNewPartner'
import AddTeamMember from 'core/Team/AddTeamMember'
import Dashboard from 'core/Dashboard'
import EditTeamMember from 'core/Team/EditTeamMember'
import Header from 'core/Header'
import Navigation from 'core/Navigation'
import Partners from 'core/Partners'
import Team from 'core/Team'
import EditPartner from 'core/Partners/EditPartner'
import History from 'core/History'
import Aggregators from 'core/Aggregators'
import Transactions from 'core/Transactions'
import { store } from 'common/store'
import Settings from 'core/Settings'
import { fetchApplicationData } from 'common/store/app/actions'
import BrandListType from 'core/BrandsFromType'
import SATransactions from 'core/Transactions/SATransaction'
import CatalogAudit from 'core/CatalogAudit'
import ErrorLogs from 'core/ErrorLogs'
import Broadcast from 'core/Broadcast'
import PartnerErrorLogs from '../PartnerErrorLogs'
import AddPersistentList from 'core/AddPersistentList'
import PhazeRedeemTab from 'core/Transactions/PhazeRedeemTab'
const { Content } = Layout

const PageContainer: React.FC = () => {
  useEffect(() => {
    store.dispatch(fetchApplicationData())
  }, [])
  return (
    <Container>
      <Layout>
        <Navigation />
        <MainSection>
          <Header />
          <Content>
          <Routes>
          <Route path={RoutePaths.DASHBOARD} element={<Dashboard />} />
          <Route path={RoutePaths.ADD_NEW_PARTNER} element={<AddNewPartner />} />
          <Route path={`${RoutePaths.EDIT_PARTNER}/:accountId`} element={<EditPartner />} />
          <Route path={RoutePaths.PARTNERS} element={<Partners />} />
          <Route path={RoutePaths.ADD_NEW_TEAM_MEMBER} element={<AddTeamMember />} />
          <Route path={`${RoutePaths.EDIT_TEAM_MEMBER}/:userId`} element={<EditTeamMember />} />
          <Route path={RoutePaths.TEAM} element={<Team />} />
          <Route path={RoutePaths.HISTORY} element={<History />} />
          <Route path={RoutePaths.AGGREGATORS} element={<Aggregators />} />
          <Route path={RoutePaths.ERRORLOGS} element={<ErrorLogs />} />
          <Route path={RoutePaths.PHAZE_ADMIN_TRANSACTIONS} element={<PhazeRedeemTab />} />
          <Route path={RoutePaths.TRANSACTIONS} element={<Transactions />} />
          <Route path={RoutePaths.SETTINGS} element={<Settings />} />
          <Route path={RoutePaths.ADD_PERSISTENT_LIST} element={<AddPersistentList brandListType='mapped' />} />
          <Route path={RoutePaths.ADD_EXCLUSION_LIST} element={<AddPersistentList brandListType='exclusion' />} />
          <Route path={RoutePaths.SUPERADMINTRANSACTION} element={<SATransactions />} />
          <Route path={RoutePaths.EXCLUSION} element={<BrandListType brandListType='exclusion' />} />
          <Route path={RoutePaths.MAPPED_PRODUCTS} element={<BrandListType brandListType='mapped' />} />
          <Route path={RoutePaths.CATALOG_AUDIT} element={<CatalogAudit />} />
          <Route path={RoutePaths.BROADCAST} element={<Broadcast />} />
          <Route path={RoutePaths.MY_ERROR_LOGS} element={<PartnerErrorLogs />} />
          {/* <Route path={RoutePaths.MAPPED_PRODUCTS} element={<MappedProducts />} /> */}
        </Routes>
          </Content>
        </MainSection>
      </Layout>
    </Container>
  )
}

export default PageContainer
