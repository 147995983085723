import React from 'react'

import { Menu, Icon, StyledLink } from './styledComponents'

import { RoutePaths } from 'core/Router/routePaths'

const PhazeAdminNavigation: React.FC = () => {
  return (
    <Menu mode='inline'>
      <Menu.Item key='home'>
        <StyledLink to={RoutePaths.DASHBOARD}>
          <Icon type='HomeOutlined' /> <span>Dashboard</span>
        </StyledLink>
      </Menu.Item>

      <Menu.Item key='partners'>
        <StyledLink to={RoutePaths.PARTNERS}>
          <Icon type='DeploymentUnitOutlined' /> <span>Partners</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='Aggregators'>
        <StyledLink to={RoutePaths.AGGREGATORS}>
          <Icon type='ApartmentOutlined' /> <span>Aggregators</span>
        </StyledLink>
      </Menu.Item>
      <Menu.Item key='PhazeAdminTransactions'>
        <StyledLink to={RoutePaths.PHAZE_ADMIN_TRANSACTIONS}>
          <Icon type='SwapOutlined' /> <span>Phaze Transactions</span>
        </StyledLink>
      </Menu.Item>
    </Menu>
  )
}

export default PhazeAdminNavigation
