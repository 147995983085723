import { useEffect, useState } from 'react';
import { client } from 'common/api/client';
import { URL_PHAZE_ADMIN_TRANSACTIONS } from 'common/api/paths';
import { DateTime } from 'luxon';
import { RoleType } from 'common/store/auth/slice';

type Args = {
  perPage: number
  currentPage: number
  orderBy: {
    field: string
    sort: string
  }
  searchItems?: {
    productId?: string
    supplierFacingProductId?: string
    orderId?: string
    organizationName?: string
    productName?: string
    securityKey?: string
    status?: string
    state?: string
    attempt?: null | number
    attemptWithAggregator?: null | number
    ipAddress?: null | number
  }
  fromDate: string
  toDate: string
  expiryDateFrom?: string
  expiryDateTo?: string
  role: string
};

export type PhazeRedeemTransactionsResponse = {
  result: PhazeRedeemTransaction[];
  totalPages: number;
  totalCount: number;
  currentPage: number;
};

export type PhazeRedeemTransaction = {
  id: number
  organizationName: string
  productPrice: string
  productId: string
  productName: string
  supplierFacingProductId: string
  orderId: string
  status: string
  state: string
  attempt: number
  phazeRedeem: boolean
  attemptWithAggregator: number
  errors: string[]
  successData?: string

  // if the user purchased through purchaseByEndUser.ts
  finalProductId: string | undefined
  finalProductName: string | undefined


  redeemDetails?: {
    url: string
    code: string
    validityDate: Date
    voucherCurrency: string
    faceValue: number
    redemptionLocation: {
      blockedReason: number, ipAddress: string, isVPN: boolean, isProxy: boolean, country: string, isTOR: boolean
}[]
  }

  expiryDate: Date
  created_at: Date
}

const useFetchPhazeAdminTransactions = ({
  perPage,
  currentPage,
  orderBy,
  searchItems,
  fromDate,
  toDate,
  expiryDateFrom,
  expiryDateTo,
  role
}: Args) => {
  
  const [data, setData] = useState<PhazeRedeemTransactionsResponse>({
    result: [],
    totalPages: 0,
    totalCount: 0,
    currentPage: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

const params = {
  perPage,
  currentPage,
  order: orderBy.field,
  sortBy: orderBy.sort,
  fromDate,
  toDate,
  productId: searchItems?.productId,
    supplierFacingProductId: searchItems?.supplierFacingProductId,
    orderId: searchItems?.orderId,
    organizationName: searchItems?.organizationName,
    productName: searchItems?.productName,
    attemptWithAggregator: searchItems?.attemptWithAggregator,
    attempt: searchItems?.attempt,
    securityKey: searchItems?.securityKey
      ? decodeURIComponent(searchItems?.securityKey)
      : undefined,
    status: searchItems?.status,
    state: searchItems?.state,
    expiryDateFrom: expiryDateFrom
      ? DateTime.fromISO(expiryDateFrom)
          .endOf('day')
          .toFormat('yyyy-MM-dd')
      : undefined,
    expiryDateTo: expiryDateTo
      ? DateTime.fromISO(expiryDateTo)
          .plus({ days: 1 })
          .endOf('day')
          .toFormat('yyyy-MM-dd')
      : undefined,
    ipAddress: searchItems?.ipAddress
};

  const fetchData = () => {
    setLoading(true);
    client
      .get<PhazeRedeemTransactionsResponse>(URL_PHAZE_ADMIN_TRANSACTIONS, {
        params
      }) 
      .then(r => setData(r.data))
      .catch(e => setError(e.response?.data || 'An error occurred'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if(role && role === RoleType.PhazeAdmin){
      fetchData();
    }
  }, [perPage, currentPage, orderBy, fromDate, toDate, expiryDateFrom, expiryDateTo, role, searchItems]);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchPhazeAdminTransactions;