export const RoutePaths = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  RESET_PASSWORD: 'reset-password',
  CONFIRM_PASSWORD: '/confirm-password',
  CHANGE_TEMPORARY_PASSWORD: 'change-temporary-password',
  PARTNERS: '/partners',
  ADD_NEW_PARTNER: '/partners/add-new-partner',
  EDIT_PARTNER: '/partners/edit-partner',
  TEAM: '/team',
  ADD_NEW_TEAM_MEMBER: '/team/add-new-team-member',
  EDIT_TEAM_MEMBER: '/team/edit-team-member',
  PHAZE_ADMIN_TRANSACTIONS: '/user/phaze-redeem-transactions',
  TRANSACTIONS: '/transactions',
  HISTORY: '/history',
  REDEEM: '/redeem',
  REDEEM_BRAND_DETAILS: '/redeem/brand-details',
  REDEEM_BRAND_PUCRAHSE_STATUS: '/redeem/brand-details/purchase',
  EXCLUSION: '/exclusion-list',
  ADD_EXCLUSION_LIST: '/exclusion-list/add-exclusion-list',
  MAPPED_PRODUCTS: '/mapped-product-list',
  ADD_PERSISTENT_LIST: '/mapped-product-list/add-persistent-list',
  AGGREGATORS: '/aggregators',
  CATALOG_AUDIT: '/catalog-updates',
  SUPERADMINTRANSACTION: '/transaction-all',
  ERRORLOGS: '/error-logs',
  BROADCAST: '/broadcast',
  SETTINGS: '/settings',
  ISSUES: '/issues',
  MY_ERROR_LOGS: '/my/error-logs'
}
