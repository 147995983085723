import React from 'react'
import { Route, Routes as RouterRoutes, Navigate } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { RoutePaths } from 'core/Router/routePaths'
import LoginContainer from '../LoginContainer'
import PageContainer from 'common/components/PageContainer'
import PublicPageContainer from 'common/components/PublicPageContainer'
import PublicRoute from './PublicRoute'

export const Routes: React.FC = () => (
  <>
  <RouterRoutes>
      <Route path={`${RoutePaths.LOGIN}/*`} element={<LoginContainer />} />
      <Route path={RoutePaths.RESET_PASSWORD} element={<LoginContainer />} />
      <Route path={RoutePaths.CHANGE_TEMPORARY_PASSWORD} element={<LoginContainer />} />
    {/* Private Route  */}
    <Route element={<PrivateRoute component={PageContainer} />}>
      <Route path={RoutePaths.DASHBOARD} element={<PageContainer />} />
      <Route path={RoutePaths.ADD_NEW_PARTNER} element={<PageContainer />} />
      <Route path={`${RoutePaths.EDIT_PARTNER}/:accountId`} element={<PageContainer />} />
      <Route path={RoutePaths.ADD_NEW_TEAM_MEMBER} element={<PageContainer />} />
      <Route path={`${RoutePaths.EDIT_TEAM_MEMBER}/:userId`} element={<PageContainer />} />
      <Route path={RoutePaths.PARTNERS} element={<PageContainer />} />
      <Route path={RoutePaths.TEAM} element={<PageContainer />} />
      <Route path={RoutePaths.TRANSACTIONS} element={<PageContainer />} />
      <Route path={RoutePaths.PHAZE_ADMIN_TRANSACTIONS} element={<PageContainer />} />
      <Route path={RoutePaths.SETTINGS} element={<PageContainer />} />
      <Route path={RoutePaths.HISTORY} element={<PageContainer />} />
      <Route path={RoutePaths.EXCLUSION} element={<PageContainer />} />
      <Route path={RoutePaths.SUPERADMINTRANSACTION} element={<PageContainer />} />
      <Route path={RoutePaths.MAPPED_PRODUCTS} element={<PageContainer />} />
      <Route path={RoutePaths.ADD_PERSISTENT_LIST} element={<PageContainer />} />
      <Route path={RoutePaths.ADD_EXCLUSION_LIST} element={<PageContainer />} />
      <Route path={RoutePaths.AGGREGATORS} element={<PageContainer />} />
      <Route path={RoutePaths.CATALOG_AUDIT} element={<PageContainer />} />
      <Route path={RoutePaths.ERRORLOGS} element={<PageContainer />} />
      <Route path={RoutePaths.BROADCAST} element={<PageContainer />} />
      <Route path={RoutePaths.MY_ERROR_LOGS} element={<PageContainer />} />
    </Route>
    {/* Public Routes */}
    <Route element={<PublicRoute />}>
      <Route path={RoutePaths.REDEEM} element={<PublicPageContainer />} />
      <Route path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId/:denomination`} element={<PublicPageContainer />} />
      <Route path={`${RoutePaths.REDEEM_BRAND_DETAILS}/:productId`} element={<PublicPageContainer />} />
      <Route path={`${RoutePaths.REDEEM_BRAND_PUCRAHSE_STATUS}/:orderId`} element={<PublicPageContainer />} />
      <Route path={RoutePaths.ISSUES} element={<PublicPageContainer />} />
    </Route>
    <Route path='/'  element={<Navigate replace to={RoutePaths.DASHBOARD} />} />
  </RouterRoutes>
  </>
)
