import { Button, Input, Modal } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { DatePicker } from 'common/components/DatePicker'
import { client } from 'common/api/client'
import { ContentContainer } from 'common/components/ContentContainer'
import { openNotification } from 'common/components/openNotification'
import PaginatedTable from 'common/components/PaginatedTable'
import { TableContainer } from 'common/components/PaginatedTable/styledComponents'
import useFetchPhazeRedeemTransactions, {
  PhazeRedeemTransaction,
  TransactionBlockedReasons
} from 'common/hooks/useFetchPhazeRedeemDetails'
import useFetchPhazeAdminTransactions from 'common/hooks/useFetchPhazeAdminRedeemTransactions';
import { useSelector } from 'react-redux'
import { RootState } from 'common/store/rootReducer'
import { RoleType } from 'common/store/auth/slice'
import { Filters } from 'core/TransactionsTable/styledComponents'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
dayjs.extend(utc)

const PhazeRedeemTab: FC = () => {
  const [currentPage, setPage] = useState(1)
  const [searchItems, setSearchItems] = useState<any>({
    productIs: '',
    supplierFacingProductId: '',
    orderId: '',
    organizationName: '',
    productName: '',
    status: '',
    securityKey: '',
    state: '',
    attempt: null,
    attemptWithAggregator: null,
    ipAddress: ''
  })

  const role = useSelector((state: RootState) => state.auth.role);
  const isPhazeAdmin = role === RoleType.PhazeAdmin;

  

  const onSortChange = (sortBy: string, order: string | null) => {

    if(order && sortBy)
      setOrderBy({
        sort: order,
        field: sortBy
      })
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const onPageSizeChange = (val: number) => {
    setPageSize(val)
    setPage(1)
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm()

    // const [text] = selectedKeys
    // onSearched(dataIndex, text)
    //setSearchItems({ [dataIndex]: text })
  }

  const handleReset = (clearFilters: any, confirm: any) => {

    clearFilters()

    confirm()

    setPage(1)
  }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
    console.log(filterField)
    if(filterField)
      onSearched(filterField, filterValue)
  }

  const onSearched = (field:string, value: string | null) => {

    /* if(field == "status")
      setStatus(value ? value : "") */

    console.log(field, value)

    searchItems[field] = value ? value : ''
    setSearchItems({ ...searchItems })
    setPage(1)
  }

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: {
      setSelectedKeys: any
      selectedKeys: any
      confirm: any
      clearFilters: any
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys ? selectedKeys[0] : ''}
          onChange={e => {
            console.log(e)
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size='small'
          style={{ width: 90, padding: 0 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any) => {
      return true
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) => (text)
  })

  const [orderBy, setOrderBy] = useState({
    sort: 'DESC',
    field: 'created_at'
  })

  const startOfDay = dayjs()
    .startOf('day')
    .format('YYYY-MM-DD')

  const [expiryDateFrom, setExpiryDateFrom] = useState<string>()
  const [expiryDateTo, setExpiryDateTo] = useState<string>()

  const [perPage, setPageSize] = useState(10)

  const dateFormat = 'YYYY-MM-DD'
  const defaultFromDate = dayjs()
    .utc()
    .subtract(360, 'd')
    .startOf('day')
    .format()
  const defaultToDate = dayjs()
    .utc()
    .endOf('day')
    .format()

  const [toDate, setToDate] = useState(defaultToDate)
  const [fromDate, setFromDate] = useState(defaultFromDate)

  const phazeAdminData = useFetchPhazeAdminTransactions({ perPage, currentPage, orderBy, searchItems, fromDate, toDate, expiryDateFrom, expiryDateTo, role });
  const otherRolesData = useFetchPhazeRedeemTransactions({ perPage, currentPage, orderBy, searchItems, fromDate, toDate, expiryDateFrom, expiryDateTo, role });

  const { data, loading, refetch } = isPhazeAdmin ? phazeAdminData : otherRolesData;

  const columns: ColumnProps<any>[] = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return dayjs(date).format('YYYY-MM-DD')
      },
      sorter: (a: any, b: any) => {
        return 0
      },
      width: 100
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      key: 'orderId',
      width: 140,
      sorter: (a: any, b: any) => {
        return 0
      },
      ...getColumnSearchProps('orderId')
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      key: 'organizationName',
      width: 150,
      sorter: (a: any, b: any) => {
        return 0
      },
      ...getColumnSearchProps('organizationName')
    },
    {
      title: 'Product Price',
      dataIndex: 'productPrice',
      key: 'productPrice',
      align: 'center' as 'center',
      width: 120
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'center' as 'center',
      width: 150,
      render: (expiryDate: string, record: PhazeRedeemTransaction) => {
        const Component = () => {
          const [date, setDate] = useState(expiryDate)

          const updateExpiryDate = (date: string) =>
            client
              .put(`/phaze-redeem-transactions/${record.id}`, {
                expiryDate: date
              })
              .then(refetch)
              .catch(e => {
                openNotification(e?.response?.data?.error || 'Error', 'error')
              })

          return record.state === 'waiting' || record.state === 'void' ? (
            <>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(date).utc()}
                format={dateFormat}
                onChange={(_, dateString: any) => {
                  setDate(dateString)
                  if (
                    window.confirm(
                      'Are you sure you want to update expiry date?'
                    )
                  ) {
                    updateExpiryDate(dateString).then(() => {
                      openNotification('Expiry date updated', 'success')
                    })
                  } else {
                    setDate(expiryDate)
                  }
                }}
              />
            </>
          ) : expiryDate ? (
            <span>{dayjs(expiryDate).format(dateFormat)}</span>
          ) : (
            <>Null</>
          )
        }

        return <Component />
      }
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Product Id',
      dataIndex: 'productId',
      key: 'productId',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('productId')
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Supplier Product Id',
      dataIndex: 'supplierFacingProductId',
      key: 'supplierFacingProductId',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('supplierFacingProductId')
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('productName')
    },
    {
      title: 'Product Id (final)',
      dataIndex: 'finalProductId',
      key: 'finalProductId',
      align: 'center' as 'center',
      width: 120
    },
    {
      title: 'Product name (final)',
      dataIndex: 'finalProductName',
      key: 'finalProductName',
      align: 'center' as 'center',
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Processed',
          value: 'processed'
        },
        {
          text: 'Failed',
          value: 'failed'
        },
        {
          text: 'Pending',
          value: 'pending'
        }
      ],
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
      align: 'center' as 'center',
      width: 120
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      align: 'center' as 'center',
      width: 120,
      filters: [
        {
          text: 'Waiting',
          value: 'waiting'
        },
        {
          text: 'Cancelled',
          value: 'cancelled'
        },
        {
          text: 'Void',
          value: 'void'
        },
        {
          text: 'Issued',
          value: 'issued'
        }
      ],
      filterMultiple: false
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Attempt With Vendor',
      dataIndex: 'attemptWithAggregator',
      key: 'attemptWithAggregator',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('attemptWithAggregator'),
      render: (value: any, record: PhazeRedeemTransaction) => {
        const AttemptInput: FC = () => {
          const [attempt, setAttempt] = useState(record.attemptWithAggregator)

          const submitHandler = () => {
            if (attempt === record.attemptWithAggregator) return

            const message =
              'Are you sure you want to update attempt with vendor?'

            if (window.confirm(message)) {
              client
                .put(`/phaze-redeem-transactions/${record.id}`, {
                  attemptWithAggregator: attempt
                })
                .then(refetch)
                .catch(e => {
                  openNotification(e?.response?.data?.error || 'Error', 'error')
                })
              return
            }

            setAttempt(record.attemptWithAggregator)
          }

          return (
            <Input
              value={attempt}
              onChange={e => {
                const value = Number(e.target.value)
                setAttempt(value < 3 ? value : 3)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                submitHandler(); 
                }
              }}
              disabled={record.state !== 'waiting'}
            />
          )
        }

        return <AttemptInput />
      }
    },
    {
      title: 'Cancel Card',
      align: 'center' as 'center',
      width: 120,
      render: (value: PhazeRedeemTransaction) => {
        const cancelCardHandler = () => {
          if (!window.confirm('Are you sure you want to cancel the card?')) {
            return
          }

          client
            .put(`/phaze-redeem-transactions/${value.id}`, {
              cancelCard: true
            })
            .then(refetch)
            .catch(e => {
              openNotification(e?.response?.data?.erorr || 'Error', 'error')
            })
        }

        return (
          <Button
            disabled={value.state !== 'waiting'}
            onClick={cancelCardHandler}
            danger
            key={Math.random() * 1000}
          >
            Cancel Card
          </Button>
        )
      }
    },
    {
      title: 'Unblock card',
      dataIndex: 'attempt',
      align: 'center' as 'center',
      width: 120,
      filters: [
        {
          text: 'Blocked',
          value: 'blocked'
        },
        {
          text: 'UnBlocked',
          value: 'unblocked'
        }
      ],
      filterMultiple: false,      
      render: (value: number, record: PhazeRedeemTransaction) => {
        const unblockCard = () => {
          if (!window.confirm('Are you sure you want to unblock the card?')) {
            return
          }

          client
            .put(`/phaze-redeem-transactions/${record.id}`, {
              unblock: true
            })
            .then(refetch)
            .catch(e => {
              openNotification(e?.response?.data?.erorr || 'Error', 'error')
            })
        }

        return (
          <Button
            disabled={value < 10}
            onClick={unblockCard}
            danger
            key={Math.random() * 1000}
          >
            Unblock Card
          </Button>
        )
      }
    },
    {
      title: 'Aggregator errors',
      // dataIndex: '',
      key: 'errors',
      align: 'center' as 'center',
      width: 120,
      render: (value: PhazeRedeemTransaction) => {
        const Component = () => {
          const [visible, setVisible] = useState(false)

          return (
            <>
              <Button
                disabled={!value?.errors?.length}
                onClick={() => setVisible(true)}
              >
                Show Errors
              </Button>

              <Modal
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                visible={visible}
              >
                <div>
                  <p>Aggregator Errors for order id: </p>
                  <span
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    {value.orderId}
                  </span>
                </div>
                <div>
                  <p>Errors:</p>
                  {value?.errors?.map((error, _idx) => (
                    <div key={_idx}>
                      <p>{error === 'null' ? '' : error}</p>
                    </div>
                  ))}
                </div>
              </Modal>
            </>
          )
        }

        return <Component />
      }
    },
    {
      title: 'Aggregator success data',
      // dataIndex: '',
      key: 'successData',
      align: 'center' as 'center',
      width: 120,
      render: (value: PhazeRedeemTransaction) => {
        const Component = () => {
          const [show, setShow] = useState(false)

          return (
            <>
              <Button
                onClick={() => setShow(true)}
                disabled={!value.successData}
              >
                Show Success Data
              </Button>
              <Modal
                onOk={() => setShow(false)}
                onCancel={() => setShow(false)}
                visible={show}
              >
                {value.successData ? value.successData : 'No data'}
              </Modal>
            </>
          )
        }
        return <Component />
      }
    },
    {
      title: 'Details',
      // dataIndex: '',
      key: 'transactionDetails',
      align: 'center' as 'center',
      width: 120,
      render: (value: PhazeRedeemTransaction) => {
        const Component = () => {
          const [show, setShow] = useState(false)

          return (
            <>
              <Button
                disabled={!value.transactionDetails}
                onClick={() => {
                  setShow(true)
                }}
              >
                Show Details
              </Button>

              <Modal
                onCancel={() => setShow(false)}
                onOk={() => setShow(false)}
                visible={show}
              >
                <div>Transaction details</div>

                <p style={{ fontWeight: 'bold' }}>Before</p>
                <p>
                  Product discount percent:
                  {value.transactionDetails?.before?.productDiscountPercent}
                </p>
                <p>
                  Product price:
                  {value.transactionDetails?.before?.productPrice}
                </p>
                <p>
                  Partner Commission:{' '}
                  {value.transactionDetails?.before?.partnerCommission}
                </p>
                <p>
                  Phaze Commission:{' '}
                  {value.transactionDetails?.before?.phazeCommission}
                </p>
                <p>
                  Product price in account currency:{' '}
                  {
                    value.transactionDetails?.before
                      ?.productPriceInAccountCurrency
                  }
                </p>
                <p>
                  Cost for transaction:{' '}
                  {value.transactionDetails?.before?.costForTransaction}
                </p>
                <p>
                  Transaction fee in account currency:{' '}
                  {
                    value.transactionDetails?.before
                      ?.transactionFeeInAccountCurrency
                  }
                </p>

                {value.transactionDetails?.after && (
                  <>
                    <p style={{ fontWeight: 'bold' }}>After</p>

                    <p>
                      Product discount percent:{' '}
                      {value.transactionDetails?.after?.productDiscountPercent}
                    </p>
                    <p>
                      Product price:{' '}
                      {value.transactionDetails?.after?.productPrice}
                    </p>
                    <p>
                      Partner Commission:{' '}
                      {value.transactionDetails?.after?.partnerCommission}
                    </p>
                    <p>
                      Phaze Commission:{' '}
                      {value.transactionDetails?.after?.phazeCommission}
                    </p>
                    <p>
                      Product price in account currency:{' '}
                      {
                        value.transactionDetails?.after
                          ?.productPriceInAccountCurrency
                      }
                    </p>
                    <p>
                      Cost for transaction:{' '}
                      {value.transactionDetails?.after?.costForTransaction}
                    </p>
                    <p>
                      Transaction fee in account currency:{' '}
                      {
                        value.transactionDetails?.after
                          ?.transactionFeeInAccountCurrency
                      }
                    </p>
                  </>
                )}
              </Modal>
            </>
          )
        }

        return <Component />
      }
    },
    //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Redeem details',
      // dataIndex: '',
      key: 'securityKey',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('securityKey'),
      render: (value: PhazeRedeemTransaction) => {
        const Component = () => {
          const [show, setShow] = useState(false)

          return (
            <>
              <Button
                disabled={!value.redeemDetails}
                onClick={() => setShow(true)}
              >
                Show Details
              </Button>
              {value.redeemDetails && (
                <Modal
                  visible={show}
                  onOk={() => setShow(false)}
                  onCancel={() => setShow(false)}
                >
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Order id: </span>{' '}
                    {value.orderId}
                  </p>

                  <p>
                    <span style={{ fontWeight: 'bold' }}>URL: </span>{' '}
                    {value.redeemDetails?.url}
                  </p>

                  <p>
                    <span style={{ fontWeight: 'bold' }}>Security Code: </span>{' '}
                    {value.redeemDetails?.code}
                  </p>

                  <p>
                    <span style={{ fontWeight: 'bold' }}>Validity date: </span>{' '}
                    {dayjs(value.redeemDetails?.validityDate).format(
                      'DD MMM YYYY'
                    )}
                  </p>

                  <p>
                    <span style={{ fontWeight: 'bold' }}>
                      Voucher currency:{' '}
                    </span>{' '}
                    {value.redeemDetails?.voucherCurrency}
                  </p>

                  <p>
                    <span style={{ fontWeight: 'bold' }}>Face value: </span>{' '}
                    {value.redeemDetails?.faceValue}
                  </p>
                </Modal>
              )}
            </>
          )
        }

        return <Component />
      }
    },
        //TO-DO: Update
    //@ts-ignore 
    {
      title: 'Redeem Location Details',
      // dataIndex: '',
      key: 'ipAddress',
      align: 'center' as 'center',
      width: 120,
      ...getColumnSearchProps('ipAddress'),
      render: (value: PhazeRedeemTransaction) => {
        const Component = () => {
          const [show, setShow] = useState(false)

          return (
            <>
              <Button
                disabled={!value.redeemDetails?.redemptionLocation}
                onClick={() => setShow(true)}
              >
                Show Details
              </Button>
              {value.redeemDetails && (
                <Modal
                  visible={show}
                  onOk={() => setShow(false)}
                  onCancel={() => setShow(false)}
                >
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Order id: </span>{' '}
                    {value.orderId}
                  </p>

                  {value.redeemDetails?.redemptionLocation?.length && (<p>
                    {
                      value.redeemDetails?.redemptionLocation?.map((rl,i)=>{
                          return (
                            <>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>Reason to Block: </span>{' '}
                                {TransactionBlockedReasons[rl.blockedReason]}
                              </p>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>IP Address: </span>{' '}
                                {rl.ipAddress}
                              </p>
                              <p>
                                <span style={{ fontWeight: 'bold' }}> Accessing through VPN: </span>{' '}
                                {rl.isVPN ? 'YES' : 'NO'}
                              </p>
                              <p>
                                <span style={{ fontWeight: 'bold' }}> Accessing through TOR: </span>{' '}
                                {rl.isTOR ? 'YES' : 'NO'}
                              </p>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>Accessing through Proxy: </span>{' '}
                                {rl.isProxy ? 'YES' : 'NO'}
                              </p>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>Country: </span>{' '}
                                {rl.country}
                              </p>
                            </>
                          )
                      })
                    }
                  </p>)}
                </Modal>
              )}
            </>
          )
        }

        return <Component />
      }
    }
  ]


  const restrictedColumns = ['successData', 'transactionDetails', 'securityKey'];

  const filteredColumns = isPhazeAdmin
    ? columns.filter(column => !restrictedColumns.includes(column.key as string))
    : columns;

  const updateToDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setToDate(value)
      setPage(1)
    }
  }

  const updateFromDate = (value: string | undefined) => {
    if (value) {
      // Moment(value).utc().format()
      setFromDate(value)
      setPage(1)
    }
  }

  //Disable the date toDate which is less than start date or greater than today's date
  const disabledDate = (value: any | null) => {
    const endDate = dayjs(value)
      .utc()
      .startOf('day')
      .format()
    // Can not select days before today and today
    return (
      endDate < fromDate ||
      endDate >
        dayjs
          .utc()
          .endOf('day')
          .format()
    )
  }

  //Disable the date fromDate which is less than equal to today's date
  const disabledStartDate = (value: any | null) => {
    const startDate = dayjs(value)
      .utc()
      .startOf('day')
      .format()
    return (
      startDate >
      dayjs
        .utc()
        .endOf('day')
        .format()
    )
  }

  return (
    <div>
      <Filters
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 50,
          marginTop: 10
        }}
      >
        <div
          style={{
            display: 'flex',
            placeItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <div>Expiry date</div>
          <div
            style={{
              display: 'flex',
              placeItems: 'center',
              justifyContent: 'center',
              gap: 5
            }}
          >
            <DatePicker
              allowClear={false}
              defaultValue={undefined}
              format={dateFormat}
              onChange={(date: any) => {
                return !!date
                  ? setExpiryDateFrom(
                      date
                        .utc()
                        .startOf('day')
                        .format('YYYY-MM-DD')
                    )
                  : setExpiryDateFrom(startOfDay)
              }}
              data-testid='test-to-date'
            />
            <DatePicker
              allowClear={false}
              defaultValue={undefined}
              format={dateFormat}
              disabledDate={(cur: any) =>
                !!cur &&
                !!expiryDateFrom &&
                cur.isBefore(dayjs(expiryDateFrom).utc())
              }
              onChange={(date: any) => {
                return !!date
                  ? setExpiryDateTo(
                      date
                        .utc()
                        .startOf('day')
                        .format('YYYY-MM-DD')
                    )
                  : setExpiryDateTo(startOfDay)
              }}
              data-testid='test-to-date'
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 10
          }}
        >
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(fromDate).utc()}
            format={dateFormat}
            disabledDate={disabledStartDate}
            onChange={(date: any) =>
              updateFromDate(
                date
                  ?.utc()
                  .startOf('day')
                  .format()
              )
            }
            data-testid='test-from-date'
          />
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(toDate).utc()}
            format={dateFormat}
            disabledDate={disabledDate}
            onChange={(date: any) =>
              updateToDate(
                date
                  ?.utc()
                  .endOf('day')
                  .format()
              )
            }
            data-testid='test-to-date'
          />
        </div>
      </Filters>

      <TableContainer>
        <ContentContainer>
          <PaginatedTable
            total={data?.totalCount}
            loading={loading}
            columns={filteredColumns}
            data={data?.result}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            currentPage={currentPage}
            pageSize={perPage}
            onSortChange={onSortChange}
            onFilterApplied={onFilterApplied}
            rowKey='orderId'
          />
        </ContentContainer>
      </TableContainer>
    </div>
  )
}

export default PhazeRedeemTab
