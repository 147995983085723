import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store/rootReducer';

import ViewContainer from 'common/components/ViewContainer';
import TransactionsTable from 'core/TransactionsTable';
import PhazeRedeemTab from 'core/Transactions/PhazeRedeemTab';
import { RoleType } from 'common/store/auth/slice';


const Transactions: React.FC = () => {
  const organizationName = useSelector((state: RootState) => state.auth.organizationName ?? '');
  const role = useSelector((state: RootState) => state.auth.role);
  const url = `/me/transactions`;
  console.log(url);
  
  return (
    <ViewContainer title='Transactions'>
      {role === RoleType.PhazeAdmin ? (
        <PhazeRedeemTab />
      ) : (
        <TransactionsTable role={role} fetchUrl={url} displayContainer organizationName={organizationName} />
      )}
    </ViewContainer>
  );
};

export default Transactions;
